.title {
  padding-top: 2rem !important;
  font-size: 2.5rem !important; /* Adjust based on your preference */
  text-align: center !important;
}

.logo {
  display: flex;
  justify-content: center;
  padding-top: 2rem !important;
}

.logo img {
  max-width: 100%;
  height: auto;
}

